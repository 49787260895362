<template>
    <aside class="modal">
        <div class="modal__close" @click="closeModal()">X</div>
        <slot/>
    </aside>
</template>

<script>
    import { onBeforeUnmount } from 'vue';
    import { useStore } from 'vuex';

    export default {
        emits: ['close-modal'],
        setup(props, context) {
            const store = useStore();
            function closeModal() {
                context.emit('close-modal');
            }

            store.commit('SET_STATE', {prop: 'isModalOpen', value: true});

            onBeforeUnmount( () => {
                const store = useStore();
                store.commit('SET_STATE', {prop: 'isModalOpen', value: false});
            });

            return { closeModal };
        }
    }
</script>
