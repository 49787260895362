<template>
    <section class="samples" id="samples">
        <h2>(Some of) My Work</h2>
        <p>
            {{ body }}
        </p>
        <div class="samples__grid">
            <ul class="grid">
                <li v-for="(sample, index) in samples"
                    :key="index"
                    title="Click for live preview"
                    @click="openModal(sample)"
                >
                    <picture>
                        <source :srcset=" requireImage( sample.src ) " media="min-width: 320px">
                        <img :src=" requireImage( sample.src ) " alt="Click to preview this work" />
                    </picture>
                </li>
            </ul>
        </div>
    </section>

    <teleport to="body">
        <modal v-if="selectedWork"
               @close-modal="closeModal()"
        >
            <section>
                <h2>
                    {{ selectedWork.client }} {{ selectedWork.displayType }}
                </h2>
                <iframe v-if=" selectedWork.type !== 'banner' "
                        :src="selectedWork.data"
                        :class="{ 'email': selectedWork.type === 'email' }"
                        :style="{ height: selectedWork.height + 'px' }"
                >
                </iframe>
                <iframe v-else
                        :src="selectedWork.data"
                        :style="{
                            width: `${selectedWork.width}px`,
                            height: `${selectedWork.height}px`,
                        }"
                >
                </iframe>
            </section>
        </modal>
    </teleport>
</template>

<script>
    import { ref } from 'vue';
    import Modal from '@/components/Modal';

    export default {
        components: {
            modal: Modal,
        },
        setup() {
            const publicPath = process.env.BASE_URL;
            const samples = [
                {
                    type: "email",
                    client: "Bermuda",
                    displayType: "responsive email",
                    src: `static/images/bmd_base-email.jpg`,
                    data: `static/live/bmd_100_19614_base/index.html`,
                    width: 640,
                    height: 'auto'
                },
                {
                    type: "email",
                    client: "The Outer Banks",
                    displayType: "responsive email",
                    src: `static/images/obx-pug.jpg`,
                    data: `static/live/obx_100_19662/index.html`,
                    width: 640,
                    height: 'auto'
                },
                {
                    type: "email",
                    client: "Meritage",
                    displayType: "responsive email",
                    src: `static/images/pac-email.jpg`,
                    data: `static/live/pac_100_17153/index.html`,
                    width: 640,
                    height: 'auto'
                },
                {
                    type: "banner",
                    client: "San Jose",
                    displayType: "banner",
                    src: `static/images/sjc_300-banner.png`,
                    data: `static/live/sjc_16084-banners/300/index.html`,
                    width: 300,
                    height: 250
                },
                {
                    type: "banner",
                    client: "The Outer Banks",
                    displayType: "expandable rich media banner",
                    src: `static/images/obx_rich-300.png`,
                    data: `static/live/OBX100_15107_SpringEventsRich_2017/300/index.html`,
                    width: 1150,
                    height: 750
                },
                {
                    type: "banner",
                    client: "Monterey",
                    displayType: "banner",
                    src: `static/images/mon_600-banner.png`,
                    data: `static/live/MON100_20273_MCC_Refresh_2018/600/index.html`,
                    width: 300,
                    height: 600
                }
            ];
            const body = "Over the last five years I've worked on numerous projects for numerous clients. It's impossible to list everything I've done but I've compiled a handful of some of my recent or favorite works below.";

            const selectedWork = ref(undefined);

            function openModal(data) {
                selectedWork.value = data;
            }
            function closeModal() {
                selectedWork.value = undefined;
            }
            function requireImage(url) {
                return `${publicPath}${url}`;
            }

            return {
                body,
                samples,
                selectedWork,
                closeModal,
                openModal,
                requireImage
            };
        }
    }

</script>

<style lang="scss">
    iframe {
        width: 300px;
        height: 600px;
        border: 0;
    }
</style>
